<script setup>
import { useRuntimeConfig } from "nuxt/app";
import { onMounted, ref } from "vue";
import { useUserStore } from "~/store/auth";
import { useCentreStore } from "~/store/centre";
import { useCustomerStore } from "~/store/user";

const $ability = useNuxtApp().$ability;
const userStore = useUserStore();
const centreStore = useCentreStore();
const customerStore = useCustomerStore();
const config = useRuntimeConfig();
const tokenCookie = config.public.cookieName;
const UserCookie = config.public.userCookieName;
const centreCookie = config.public.centreCookieName;
const token = userStore.getItemWithExpiry(tokenCookie);
const userStr = userStore.getItemWithExpiry(UserCookie);
const centreStr = ref(userStore.getItemWithExpiry(centreCookie));
const user = JSON.parse(userStr);

const nom = user.nom;
const prenom = user.prenom;
const email = user.email;

const initials = nom.slice(0, 1) + prenom.slice(0, 1);

const statusAdmin = ref(false);
const sousMenuAdmin = ref();
const permissions = ref([]);
const centres = ref([]);
const verify_role = ref(Boolean);
const form = {
  centre: "",
};

const props = defineProps({
  componentKey: Number
});

const logout = async () => {
  if (!token) {
    userStore.resetUserData();
    return navigateTo("/");
  } else {
    const formData = new FormData();
    const tokens = token;
    formData.append("token", tokens);
    userStore.signOut(formData);
  }
};

const width = ref(window.innerWidth);

const updateWidth = () => {
  width.value = window.innerWidth;
};

const handleResize = () => {
  var horMenu = $('[data-layout="horizontal"] .hk-menu .menu-group');
  if (horMenu.length > 0) {
    var horMenuRect = horMenu[0].getBoundingClientRect(),
      liTotalWidth = 0,
      liCount = 0,
      extraLiHide = 0;
    if (
      (horMenu.children("ul").children("li.more-nav-item").remove(),
        horMenu
          .children("ul")
          .children("li")
          .each(function (index) {
            $(this).removeAttr("style"),
              (liTotalWidth += $(this).outerWidth(!0)),
              liCount++;
          }),
        !(window.innerWidth < 1199))
    ) {
      var visibleLi =
        parseInt(horMenuRect.width / (liTotalWidth / liCount)) - 2;
      if ((visibleLi -= extraLiHide) < liCount)
        for (
          var horWrapper = (function (horMenu) {
            horMenu
              .children("ul")
              .append(
                "<li class='nav-item more-nav-item'><a class='nav-link' href='javascript:void(0);' data-bs-toggle='collapse' data-bs-target='#dash_more'><span class='nav-icon-wrap'><span class='svg-icon'><svg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-dots' width='24' height='24' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'> <path stroke='none' d='M0 0h24v24H0z' fill='none'></path> <circle cx='5' cy='12' r='1'></circle> <circle cx='12' cy='12' r='1'></circle> <circle cx='19' cy='12' r='1'></circle></svg></span></span></a><ul id='dash_more' class='nav flex-column collapse nav-children'></ul></li>"
              );
            return horMenu.children("ul").children("li.more-nav-item");
          })(horMenu),
          i = visibleLi;
          i < liCount;
          i++
        ) {
          var currentLi = horMenu.children("ul").children("li").eq(i),
            clone = currentLi.clone();
          horWrapper.children("ul").append(clone), currentLi.hide();
          if ($(".feather-icon").length > 0) feather.replace();
        }
    }
  }

  $(document)
    .on(
      "mouseenter",
      '[data-layout="horizontal"] .hk-menu .menu-content-wrap .navbar-nav  li',
      function () {
        if ($("ul", this).length) {
          var elm = $("ul:first", this);
          var off = elm.offset();
          var l = off.left;
          var w = elm.width();
          var isEntirelyVisible = l + w <= width.value;
          if (!isEntirelyVisible) {
            $(this).addClass("edge");
          }
        }
      }
    )
    .on(
      "mouseleave",
      '[data-layout="horizontal"] .hk-menu .menu-content-wrap .navbar-nav  li',
      function () {
        $(this).removeClass("edge");
      }
    );

  var navbarMenu = $('[data-layout="navbar"] .hk-menu .menu-group');
  if (navbarMenu.length > 0) {
    var navbarMenuRect = navbarMenu[0].getBoundingClientRect(),
      liTotalWidth = 0,
      liCount = 0,
      extraLiHide = 0;
    if (
      (navbarMenu.children("ul").children("li.more-nav-item").remove(),
        navbarMenu
          .children("ul")
          .children("li")
          .each(function (index) {
            $(this).removeAttr("style"),
              (liTotalWidth += $(this).outerWidth(!0)),
              liCount++;
          }),
        !(window.innerWidth < 1199))
    ) {
      var visibleLi =
        parseInt(navbarMenuRect.width / (liTotalWidth / liCount)) - 2;
      if ((visibleLi -= extraLiHide) < liCount)
        for (
          var navWrapper = (function (navbarMenu) {
            navbarMenu
              .children("ul")
              .append(
                "<li class='nav-item more-nav-item'><a class='nav-link' href='javascript:void(0);' data-bs-toggle='collapse' data-bs-target='#dash_more'><span class='nav-icon-wrap'><span class='feather-icon'><i data-feather='more-horizontal'></i></span></span></a><ul id='dash_more' class='nav flex-column collapse nav-children'></ul></li>"
              );
            return navbarMenu.children("ul").children("li.more-nav-item");
          })(navbarMenu),
          i = visibleLi;
          i < liCount;
          i++
        ) {
          var currentLi = navbarMenu.children("ul").children("li").eq(i),
            clone = currentLi.clone();
          navWrapper.children("ul").append(clone), currentLi.hide();
          if ($(".feather-icon").length > 0) feather.replace();
        }
    }
  }
  $(document)
    .on(
      "mouseenter",
      '[data-layout="navbar"] .hk-menu .menu-content-wrap .navbar-nav  li',
      function () {
        if ($("ul", this).length) {
          var elm = $("ul:first", this);
          var off = elm.offset();
          var l = off.left;
          var w = elm.width();

          var isEntirelyVisible = l + w <= width.value;
          if (!isEntirelyVisible) {
            $(this).addClass("edge");
          }
        }
      }
    )
    .on(
      "mouseleave",
      '[data-layout="navbar"] .hk-menu .menu-content-wrap .navbar-nav  li',
      function () {
        $(this).removeClass("edge");
      }
    );
};

watch(width, (newWidth, oldWidth) => {
  handleResize();
});

const updateUserMenu = async () => {
  const str = userStore.getItemWithExpiry("customer");
  const customer = JSON.parse(str);

  if (customer && customer.roles) {
    verify_role.value = customer.roles.some(
      (item) =>
        item.titre.toUpperCase() === "SUPER ADMIN" ||
        item.titre.toUpperCase() === "SUPERVISEUR"
    );

    sousMenuAdmin.value = customer.roles.flatMap((item) =>
      item.rules.map((rule) => rule.menu.name)
    );

    if (customer && customer.statusUser && customer.statusUser.value) {
      if (customer.statusUser.value === 1) {
        statusAdmin.value = true;
      }
    }
    console.log("sousMenuAdmin --> ", sousMenuAdmin);
  }
  centres.value = await centreStore.getCentre;

  if (sousMenuAdmin.value == 'superviseur') {

  }

  console.log("verif user =>", customer);
  console.log("verfi role =>", verify_role.value);
  console.log("custom =>", verify_role.value);
  console.log("sous menu => ", sousMenuAdmin.value);
  console.log("status user value =>", customer.statusUser.value);
};

onMounted(async () => {
  window.addEventListener("resize", updateWidth);
  handleResize();
  await updateUserMenu();

  let $wrapper = $(".hk-wrapper");

  var navbarNavAnchor = ".hk-menu .navbar-nav  li a,.nav-vertical li a";
  $(document).on("click", navbarNavAnchor, function (e) {
    $(this)
      .closest(".menu-group")
      .siblings()
      .find(".collapse")
      .collapse("hide");
    $(this).closest(".nav-item").siblings().find(".collapse").collapse("hide");
    $(this).closest(".nav-item").find(".collapse").collapse("hide");
    $(this)
      .closest(".menu-group")
      .siblings()
      .find('.nav-link[data-bs-toggle="collapse"]')
      .attr("aria-expanded", "false");
    $(this)
      .closest(".nav-item")
      .siblings()
      .find('.nav-link[data-bs-toggle="collapse"]')
      .attr("aria-expanded", "false");
  });

  $(document).on(
    "click",
    '[data-layout="vertical"] .navbar-toggle',
    function (e) {
      $(this).trigger("blur");
      if ($wrapper.attr("data-layout-style") == "default") {
        $wrapper.attr("data-layout-style", "collapsed");
        setTimeout(function () {
          $wrapper.attr("data-hover", "active");
        }, 250);
      } else if (
        $wrapper.attr("data-layout-style") == "collapsed" &&
        $wrapper.attr("data-hover") == "active"
      ) {
        $wrapper.attr("data-layout-style", "default");
        $wrapper.removeAttr("data-hover");
      }
      return false;
    }
  );
  $(document).on(
    "click",
    '[data-layout="vertical"] #hk_menu_backdrop',
    function (e) {
      $wrapper.attr("data-layout-style", "default");
      $wrapper.removeAttr("data-hover");
      return false;
    }
  );

  $(document).on(
    "click",
    '[data-layout="horizontal"] .navbar-toggle',
    function (e) {
      $(this).trigger("blur");
      if ($wrapper.attr("data-layout-style") == "default") {
        $wrapper.attr("data-layout-style", "collapsed");
      } else {
        $wrapper.attr("data-layout-style", "default");
      }
      return false;
    }
  );
  $(document).on(
    "click",
    '[data-layout="horizontal"] #hk_menu_backdrop',
    function (e) {
      $wrapper.attr("data-layout-style", "default");
      return false;
    }
  );

  $(document).on(
    "click",
    '[data-layout="navbar"] .navbar-toggle',
    function (e) {
      $(this).trigger("blur");
      if ($wrapper.attr("data-layout-style") == "default") {
        $wrapper.attr("data-layout-style", "collapsed");
      } else {
        $wrapper.attr("data-layout-style", "default");
      }
      return false;
    }
  );
  $(document).on(
    "click",
    '[data-layout="navbar"] #hk_menu_backdrop',
    function (e) {
      $wrapper.attr("data-layout-style", "default");
      return false;
    }
  );
});

onUnmounted(() => {
  window.removeEventListener("resize", updateWidth);
});

watch(
  () => userStore.user,
  async (newUser, oldUser) => {
    if (newUser !== oldUser) {
      await updateUserMenu();
    }
  }
);

const canManageUser = computed(() => {
  return $ability.can("manage", "user");
});

const handleCentreChange = async (id) => {
  await userStore.changecentre({ centre: id });
  // centreStr.value = userStore.getItemWithExpiry(centreCookie);
};
</script>
<template>
  <div :key="componentKey" class="hk-wrapper" data-layout="horizontal" data-layout-style="default" data-menu="light"
    data-footer="simple">
    <!-- Top Navbar -->
    <nav class="hk-navbar navbar navbar-expand-xl navbar-light fixed-top">
      <div class="container-fluid">
        <!-- Start Nav -->
        <div class="nav-start-wrap">
          <button class="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover navbar-toggle d-xl-none">
            <span class="icon"><span class="feather-icon"><i class="bx bx-menu-alt-left"></i></span></span>
          </button>
          <!-- Brand -->
          <NuxtLink class="navbar-brand d-xl-flex align-items-center" to="/dashboard">
            <img class="brand-img img-fluid" src="../assets/images/centralCare.webp" alt="brand" />
            <strong class="ms-3">{{ centreStr }}</strong>
          </NuxtLink>
          <!-- /Brand -->
        </div>
        <!-- /Start Nav -->

        <!-- End Nav -->
        <div class="nav-end-wrap">
          <ul class="navbar-nav flex-row gap-2">
            <li class="nav-item" v-if="verify_role">
              <select class="form-select" @change="handleCentreChange(form.centre)" v-model="form.centre"
                aria-label="Default select example">
                <option selected :value="form.centre">{{ centreStr }}</option>
                <option :value="centre._id" v-for="(centre, index) in centres">
                  {{ centre.nom }}
                </option>
              </select>
            </li>
            <li class="nav-item">
              <NotificationNotifDropdown />
            </li>
            <li class="nav-item">
              <div class="dropdown ps-2">
                <a class="dropdown-toggle no-caret" href="#" role="button" data-bs-display="static"
                  data-bs-toggle="dropdown" data-dropdown-animation data-bs-auto-close="outside" aria-expanded="false">
                  <div class="avatar avatar-rounded avatar-xs">
                    <img src="@/assets/images/avatar12.jpg" alt="user" class="avatar-img" />
                  </div>
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                  <div class="p-2">
                    <div class="media">
                      <div class="media-head me-2">
                        <div class="avatar avatar-primary avatar-sm avatar-rounded">
                          <span class="initial-wrap">{{ initials }}</span>
                        </div>
                      </div>
                      <div class="media-body">
                        <div class="dropdown">
                          <a href="#" class="d-block link-dark fw-medium" data-bs-auto-close="inside">{{ nom }}</a>
                        </div>
                        <div class="fs-7">{{ email }}</div>
                        <NuxtLink v-if="user" @click="logout" class="d-block fs-6 link-secondary text-nowrap"><u>Se
                            déconnecter</u></NuxtLink>
                      </div>
                    </div>
                  </div>
                  <div class="dropdown-divider"></div>
                  <NuxtLink class="dropdown-item" to="/profil">Profil</NuxtLink>
                  <!-- <div class="dropdown-divider"></div>
                  <h6 class="dropdown-header">Gérer son compte</h6>
                  <a class="dropdown-item" href="#"><span class="dropdown-icon feather-icon"><i
                        data-feather="settings"></i></span><span>Parametres</span></a> 
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">Politiques de confidentialité</a>
                  <a class="dropdown-item" href="#">Terms & Conditions</a>
                  <a class="dropdown-item" href="#">Statut système</a> -->
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- /End Nav -->
      </div>
    </nav>
    <!-- /Top Navbar -->

    <!-- Horizontal Nav -->
    <div class="hk-menu">
      <!-- Brand -->
      <div class="menu-header d-xl-none">
        <span>
          <button class="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover navbar-toggle">
            <span class="icon">
              <span class="feather-icon"><i class="bx bx-menu-alt-right"></i></span>
            </span>
          </button>
          <NuxtLink class="navbar-brand d-xl-flex align-items-center" to="/dashboard">
            <img class="brand-img img-fluid" src="../assets/images/centralCare.webp" alt="brand" />
            <strong class="ms-3">{{ centreStr }}</strong>
          </NuxtLink>
        </span>
      </div>
      <!-- /Brand -->

      <!-- Main Menu -->
      <div class="nicescroll-bar">
        <div class="menu-content-wrap">
          <div class="container-fluid menu-group">
            <ul class="navbar-nav flex-column">
              <li class="nav-item">
                <NuxtLink class="nav-link" to="/dashboard">
                  <span class="nav-icon-wrap">
                    <i class="bx bx-grid-alt"></i>
                  </span>
                  <span class="nav-link-text">Dashboard</span>
                </NuxtLink>
              </li>

              <li class="nav-item" v-if="canManageUser">
                <NuxtLink class="nav-link" to="/users" data-bs-toggle="collapse" data-bs-target="#dash_chat">
                  <span class="nav-icon-wrap">
                    <i class="bx bx-user"></i>
                  </span>
                  <span class="nav-link-text">Utilisateurs</span>
                </NuxtLink>
                <ul id="dash_chat" class="nav flex-column collapse nav-children">
                  <li class="nav-item">
                    <ul class="nav flex-column">
                      <li class="nav-item">
                        <NuxtLink class="nav-link" to="/users"><span class="nav-link-text">Liste</span></NuxtLink>
                      </li>

                      <li class="nav-item">
                        <NuxtLink class="nav-link" to="/users/assigner"><span class="nav-link-text">Utilisateur en
                            attente d'activation</span></NuxtLink>
                      </li>

                      <li class="nav-item">
                        <NuxtLink class="nav-link" to="/roles"><span class="nav-link-text">Rôles</span></NuxtLink>
                      </li>
                      <li class="nav-item">
                        <NuxtLink class="nav-link" to="/modules"><span class="nav-link-text">Modules</span></NuxtLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="nav-item" v-if="$ability.can('manage', sousMenuAdmin) ||
    ($ability.can('manage', 'Gestion des adhérents') &&
      ($ability.can('manage', 'Relance cotisation') ||
        $ability.can('manage', 'Réclamation Payement') ||
        $ability.can('manage', 'Réclamation DNS') ||
        $ability.can('manage', 'Consultation adhérent') ||
  $ability.can(
    'manage',
    'Traitement commande de carnets de consultation'
  ) ||
        $ability.can('manage', 'DNS'))) ||
    $ability.can('manage', 'all') ||
  $ability.can('manage', 'Gestion utlisateur affiliée' ||
    $ability.can('manage', 'Validation processus d\'adhésion') ||
    $ability.can('manage', 'Signature contrat')
    )
    ">
                <NuxtLink class="nav-link" data-bs-toggle="collapse" data-bs-target="#dash_adherent">
                  <span class="nav-icon-wrap">
                    <i class="bx bx-building-house"></i>
                  </span>
                  <span class="nav-link-text position-relative">Adhérents
                    <!-- <span
                      class="badge badge-danger badge-indicator position-absolute top-0 start-100"
                    ></span> -->
                  </span>
                </NuxtLink>
                <ul id="dash_adherent" class="nav flex-column collapse nav-children">
                  <!-- test -->
                  <!-- <li class="nav-item" v-if="$ability.can('manage', 'Consultation demande d\'adhésion')">
                    <NuxtLink class="nav-link" to="/user-adherents"><span class="nav-link-text">Responsables</span>
                    </NuxtLink>
                  </li> -->
                  <li class="nav-item" v-if="$ability.can('manage', 'Consultation adhérent')">
                    <NuxtLink class="nav-link" to="/adherents"><span class="nav-link-text">Adhérents</span></NuxtLink>
                  </li>
                  <li class="nav-item" v-if="
                      $ability.can(
                        'manage',
                        'Traitement commande de carnets de consultation'
                      )
                    ">
                    <NuxtLink class="nav-link" to="/bulletin-consultation">
                      <span class="nav-link-text">Carnet de consultation</span>
                    </NuxtLink>
                  </li>
                  <li class="nav-item" v-if="$ability.can('manage', 'Consultation demande d\'adhésion')
                    ">
                    <NuxtLink class="nav-link" to="/adhesion"><span class="nav-link-text">Adhésions</span></NuxtLink>
                  </li>
                  <li class="nav-item" v-if="$ability.can('manage', 'Signature contrat')
                  ">
                    <NuxtLink class="nav-link" to="/signature"><span class="nav-link-text">Signature</span></NuxtLink>
                  </li>
                  <li class="nav-item" v-if="$ability.can('manage', 'Consultation adhérent')
                  ">
                    <NuxtLink class="nav-link" to="/registre"><span class="nav-link-text">Registre</span></NuxtLink>
                  </li>
                  <li class="nav-item" v-if="$ability.can('manage', 'DNS')">
                    <NuxtLink class="nav-link" to="" data-bs-toggle="collapse" data-bs-target="#dash_dns">
                      <span class="nav-link-text position-relative">DNS
                        <!-- <span
                          class="badge badge-danger badge-indicator position-absolute top-0 start-100"
                        ></span> -->
                      </span>
                    </NuxtLink>
                    <ul id="dash_dns" class="nav flex-column collapse nav-children">
                      <li class="nav-item">
                        <ul class="nav flex-column">
                          <li class="nav-item" v-if="$ability.can('manage', 'Réclamation DNS')">
                            <NuxtLink class="nav-link" to="/dns"><span
                                class="nav-link-text position-relative">Déclaration
                                <!-- <span
                                  class="badge badge-danger badge-indicator position-absolute top-0 start-100"
                                ></span> -->
                              </span>
                            </NuxtLink>
                          </li>
                          <li class="nav-item" v-if="$ability.can('manage', 'Réclamation Payement')
    ">
                            <NuxtLink class="nav-link" to="/dns/paiements"><span
                                class="nav-link-text position-relative">Paiement
                              </span>
                            </NuxtLink>
                          </li>
                          <li class="nav-item" v-if="$ability.can('manage', 'Réclamation Payement') ||
    $ability.can('manage', 'Réclamation DNS')
    ">
                            <NuxtLink class="nav-link" to="/dns/fichiers"><span
                                class="nav-link-text position-relative">Gestion des fichiers
                              </span>
                            </NuxtLink>
                          </li>
                          <!-- <li class="nav-item" v-if="$ability.can('manage', 'Relance cotisation')">
                            <NuxtLink class="nav-link" to="/dns/relances"><span
                                class="nav-link-text position-relative">Relances
                                <span
                                  class="badge badge-danger badge-indicator position-absolute top-0 start-100"
                                ></span>
                              </span>
                            </NuxtLink>
                          </li> -->
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="nav-item" v-if="$ability.can('manage', sousMenuAdmin) ||
    $ability.can('manage', 'Gestion des affiliés') ||
    $ability.can('manage', 'all')
    ">
                <NuxtLink class="nav-link" data-bs-toggle="collapse" data-bs-target="#dash_affilies">
                  <span class="nav-icon-wrap">
                    <i class="bx bx-building-house"></i>
                  </span>
                  <span class="nav-link-text">Affiliés</span>
                </NuxtLink>
                <ul id="dash_affilies" class="nav flex-column collapse nav-children">
                  <li class="nav-item" v-if="$ability.can('manage', 'Consultation liste des affiliés')
    ">
                    <NuxtLink class="nav-link" to="/affilies"><span class="nav-link-text">Liste des affiliés</span>
                    </NuxtLink>
                  </li>
                  <li class="nav-item" v-if="$ability.can('manage', 'Embauche et Débauche')">
                    <NuxtLink class="nav-link" to="/avis-embauche-debauche"><span class="nav-link-text">Avis d'embauche
                        / débauche</span></NuxtLink>
                  </li>
                  <li class="nav-item" v-if="$ability.can('manage', 'Embauche et Débauche')">
                    <NuxtLink class="nav-link" to="/avis-embauche-debauche/historique-ae-ed"><span
                        class="nav-link-text">Historique AE-AD</span></NuxtLink>
                  </li>
                </ul>
              </li>
              <li class="nav-item" v-if="
                  $ability.can('manage', sousMenuAdmin) ||
                  $ability.can('manage', 'RDV Exceptionnel') ||
                  //$ability.can('manage', 'RDV Services SST') ||
                  //$ability.can('manage', 'Autre visites') ||
                  //$ability.can('manage', 'Visite d\'embauche') ||
                  //$ability.can('manage', 'Visite d\'établissement') ||
                  //$ability.can('manage', 'Visite systématique') ||
                  $ability.can(
                    'manage',
                    'Consultation des rendez-vous d\'un service en globalité'
                  ) ||
                  $ability.can(
                    'manage',
                    'Traitement des prises de rendez-vous - RDV Services SST (Gestion des rendez-vous)'
                  ) ||
                  $ability.can(
                    'manage',
                    'Consultation des prises de rendez-vous - RDV Services SST (Gestion des rendez-vous)'
                  ) ||
                  $ability.can('manage', 'all') ||
                  $ability.can('manage', 'Consultation rdv specialiste') ||
                  $ability.can('manage', 'Traitement rdv specialiste')
                ">
                <NuxtLink class="nav-link" data-bs-toggle="collapse" data-bs-target="#dash_rdv">
                  <span class="nav-icon-wrap">
                    <i class="bx bx-alarm-exclamation"></i>
                  </span>
                  <span class="nav-link-text position-relative">Rendez-vous
                    <!-- <span
                      class="badge badge-danger badge-indicator position-absolute top-0 start-100"
                    ></span> -->
                  </span>
                </NuxtLink>
                <ul id="dash_rdv" class="nav flex-column collapse nav-children">
                  <li class="nav-item" v-if="
                      $ability.can(
                        'manage',
                        'Consultation des rendez-vous d\'un service en globalité'
                      ) ||
                      $ability.can(
                        'manage',
                        'Consultation des prises de rendez-vous - RDV Services SST (Gestion des rendez-vous)'
                      ) ||
                      $ability.can('manage', 'RDV Exceptionnel')
                    ">
                    <NuxtLink class="nav-link" to="/liste-rdv"><span class="nav-link-text">RDV exceptionnels</span>
                    </NuxtLink>
                  </li>
                  <li class="nav-item" v-if="$ability.can('manage', 'Consultation rdv specialiste') ||
    $ability.can('manage', 'Traitement rdv specialiste')
    ">
                    <NuxtLink class="nav-link" to="/rdv-specialist"><span class="nav-link-text">RDV spécialiste</span>
                    </NuxtLink>
                  </li>
                </ul>
              </li>
              <li class="nav-item" v-if="
                  $ability.can('manage', sousMenuAdmin) ||
                  $ability.can('manage', 'Visite systématique') ||
                  $ability.can('manage', 'Visite de formation') ||
                  $ability.can('manage', 'all') ||
                  $ability.can(
                    'manage',
                    'Consultation des prises de rendez-vous - visite d\'embauche (Gestion des rendez-vous)'
                  ) ||
                  $ability.can(
                    'manage',
                    'Consultation Dashboard - visite sytématique (Gestion des rendez-vous)'
                  ) ||
                  $ability.can(
                    'manage',
                    'Consultation Dashboard - visite d\'établissement (Gestion des rendez-vous)'
                  ) ||
                  $ability.can(
                    'manage',
                    'Consultation des prises de rendez-vous - RDV Services SST (Gestion des rendez-vous)'
                  )
                ">
                <NuxtLink class="nav-link" data-bs-toggle="collapse" data-bs-target="#dash_planning">
                  <span class="nav-icon-wrap">
                    <i class="bx bx-calendar"></i>
                  </span>
                  <span class="nav-link-text">Planning</span>
                </NuxtLink>
                <ul id="dash_planning" class="nav flex-column collapse nav-children">
                  <li class="nav-item">
                    <ul class="nav flex-column">
                      <li class="nav-item" v-if="$ability.can(
    'manage',
    'Consultation des prises de rendez-vous - visite d\'embauche (Gestion des rendez-vous)'
  )
    ">
                        <NuxtLink class="nav-link" to="/planning/vembauche"><span class="nav-link-text">Planning de
                            visite d'embauche</span></NuxtLink>
                      </li>
                      <li class="nav-item" v-if="$ability.can(
    'manage',
    'Consultation Dashboard - visite sytématique (Gestion des rendez-vous)'
  ) ||
    $ability.can(
      'manage',
      'Insertion planning annuel - visite systématique (Gestion des rendez-vous)'
    ) ||
    $ability.can(
      'manage',
      'Suppression planning annuel - visite systématique (Gestion des rendez-vous)'
    ) ||
    $ability.can(
      'manage',
      'Modification planning annuel - visite systématique (Gestion des rendez-vous)'
    )
    ">
                        <NuxtLink class="nav-link" to="/planning/vmo">
                          <span class="nav-link-text">Planning de visite systématique</span>
                        </NuxtLink>
                      </li>
                      <!-- <li
                        class="nav-item"
                        v-if="
                          $ability.can(
                            'manage',
                            'Consultation Dashboard - visite sytématique (Gestion des rendez-vous)'
                          ) ||
                          $ability.can(
                            'manage',
                            'Insertion planning annuel - visite systématique (Gestion des rendez-vous)'
                          ) ||
                          $ability.can(
                            'manage',
                            'Suppression planning annuel - visite systématique (Gestion des rendez-vous)'
                          ) ||
                          $ability.can(
                            'manage',
                            'Modification planning annuel - visite systématique (Gestion des rendez-vous)'
                          )
                        "
                      >
                        <NuxtLink class="nav-link" to="/planning/vmo">
                          <span class="nav-link-text"
                            >Planning de pré-embauche</span
                          >
                        </NuxtLink>
                      </li>
                      <li
                        class="nav-item"
                        v-if="
                          $ability.can(
                            'manage',
                            'Consultation Dashboard - visite sytématique (Gestion des rendez-vous)'
                          ) ||
                          $ability.can(
                            'manage',
                            'Insertion planning annuel - visite systématique (Gestion des rendez-vous)'
                          ) ||
                          $ability.can(
                            'manage',
                            'Suppression planning annuel - visite systématique (Gestion des rendez-vous)'
                          ) ||
                          $ability.can(
                            'manage',
                            'Modification planning annuel - visite systématique (Gestion des rendez-vous)'
                          )
                        "
                      >
                        <NuxtLink class="nav-link" to="/planning/vmo">
                          <span class="nav-link-text"
                            >Planning de retraite anticipé</span
                          >
                        </NuxtLink>
                      </li>
                      <li
                        class="nav-item"
                        v-if="
                          $ability.can(
                            'manage',
                            'Consultation Dashboard - visite sytématique (Gestion des rendez-vous)'
                          ) ||
                          $ability.can(
                            'manage',
                            'Insertion planning annuel - visite systématique (Gestion des rendez-vous)'
                          ) ||
                          $ability.can(
                            'manage',
                            'Suppression planning annuel - visite systématique (Gestion des rendez-vous)'
                          ) ||
                          $ability.can(
                            'manage',
                            'Modification planning annuel - visite systématique (Gestion des rendez-vous)'
                          )
                        "
                      > 
                        <NuxtLink class="nav-link" to="/planning/vmo">
                          <span class="nav-link-text"
                            >Planning de reprise de travail</span
                          >
                        </NuxtLink>
                      </li>-->
                      <li class="nav-item" v-if="$ability.can(
    'manage',
    'Consultation Dashboard - visite d\'établissement (Gestion des rendez-vous)'
  ) ||
    $ability.can(
      'manage',
      'Modification planning annuel - visite d\'établissement (Gestion des rendez-vous)'
    ) ||
    $ability.can(
      'manage',
      'Insertion planning annuel - visite d\'établissement (Gestion des rendez-vous)'
    ) ||
    $ability.can(
      'manage',
      'Suppression planning annuel - visite d\'établissement (Gestion des rendez-vous)'
    )
    ">
                        <NuxtLink class="nav-link" to="/planning/vets">
                          <span class="nav-link-text">Planning de visite d'établissement</span>
                        </NuxtLink>
                      </li>
                      <li class="nav-item" v-if="$ability.can(
    'manage',
    'Insertion planning sensibilisation'
  ) ||
    $ability.can(
      'manage',
      'Modification planning sensibilisation'
    ) ||
    $ability.can(
      'manage',
      'Annulation planning sensibilisation'
    )
    ">
                        <NuxtLink class="nav-link" to="/planning/sensibilisation"><span class="nav-link-text">Planning
                            de sensibilisation</span></NuxtLink>
                      </li>
                      <li class="nav-item" v-if="$ability.can(
    'manage',
    'Insertion planning formation'
  ) ||
    $ability.can(
      'manage',
      'Modification planning formation'
    ) ||
    $ability.can(
      'manage',
      'Annulation planning formation'
    )
    ">
                        <NuxtLink class="nav-link" to="/planning/formations"><span class="nav-link-text">Planning de
                            formations</span></NuxtLink>
                      </li>
                      <!-- <li class="nav-item">
                        <NuxtLink class="nav-link" to="/planning/persuasion"><span class="nav-link-text">Planning de
                            persuasion</span></NuxtLink>
                      </li>
                      <li class="nav-item">
                        <NuxtLink class="nav-link" to="/planning/accompagnement-appuie"><span
                            class="nav-link-text">Planning d'accompagnement et d'appuie</span>
                        </NuxtLink>
                      </li>
                      <li class="nav-item" v-if="$ability.can('manage', 'MAJ Planning de congé')">
                        <NuxtLink class="nav-link" to="/planning/conge"><span class="nav-link-text">Planning de
                            congé</span></NuxtLink>
                      </li>
                      <li class="nav-item">
                        <NuxtLink class="nav-link" to="/planning/autres-visites"><span class="nav-link-text">Planning
                            d'autres visites</span></NuxtLink>
                      </li>
                      <li class="nav-item">
                        <NuxtLink class="nav-link" to="/planning/garde"><span class="nav-link-text">Planning de
                            garde</span></NuxtLink>
                      </li> -->
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="nav-item" v-if="$ability.can('manage', sousMenuAdmin) ||
    $ability.can('manage', 'Gestion de contenu') ||
    $ability.can('manage', 'Ajout de contenu à jour') ||
    $ability.can(
      'manage',
      'Consultation de la liste des contenus disponibles'
    ) ||
    $ability.can('manage', 'Modification de contenu') ||
    $ability.can('manage', 'Suppression de contenu') ||
    $ability.can('manage', 'Accès au protocole de soin')
    ">
                <NuxtLink class="nav-link" data-bs-toggle="collapse" data-bs-target="#dash_content">
                  <span class="nav-icon-wrap">
                    <i class="bx bx-book-content"></i>
                  </span>
                  <span class="nav-link-text">Contenus</span>
                </NuxtLink>
                <ul id="dash_content" class="nav flex-column collapse nav-children">
                  <li class="nav-item">
                    <ul class="nav flex-column">
                      <!-- <li class="nav-item">
                        <NuxtLink class="nav-link" to=""><span class="nav-link-text">Réglement interieur OSTIE</span>
                        </NuxtLink>
                      </li> -->
                      <li class="nav-item" v-if="$ability.can(
    'manage',
    'Consultation de la liste des contenus disponibles'
  ) || $ability.can('manage', 'Ajout de contenu à jour')
    ">
                        <NuxtLink class="nav-link" to="/contenus/reglement-adherent"><span
                            class="nav-link-text">Règlement Adhérent</span></NuxtLink>
                      </li>
                      <li class="nav-item" v-if="$ability.can(
    'manage',
    'Consultation de la liste des contenus disponibles'
  ) || $ability.can('manage', 'Ajout de contenu à jour')
    ">
                        <NuxtLink class="nav-link" to="/edition-fdr"><span class="nav-link-text">Gestion des FDR</span>
                        </NuxtLink>
                      </li>
                      <li class="nav-item" v-if="$ability.can(
    'manage',
    'Consultation de la liste des contenus disponibles'
  ) || $ability.can('manage', 'Ajout de contenu à jour')
    ">
                        <NuxtLink class="nav-link" to="/edition-fdr/articles"><span class="nav-link-text">Articles
                            FDR</span></NuxtLink>
                      </li>
                      <!-- <li class="nav-item" v-if="$ability.can('manage', 'Accès au protocole de soin')">
                        <NuxtLink class="nav-link" to="/protocole-soin"><span class="nav-link-text">Protocoles de
                            Soin</span></NuxtLink>
                      </li>
                      <li class="nav-item">
                        <NuxtLink class="nav-link" to="" data-bs-toggle="collapse" data-bs-target="#dash_fdr">
                          <span class="nav-link-text">Production FDR</span>
                        </NuxtLink>
                        <ul id="dash_fdr" class="nav flex-column collapse nav-children">
                          <li class="nav-item">
                            <ul class="nav flex-column">
                              <li class="nav-item">
                                <NuxtLink class="nav-link" to="/editions-fdr"><span class="nav-link-text">Editions</span>
                                </NuxtLink>
                              </li>
                              <li class="nav-item">
                                <NuxtLink class="nav-link" to="/taggage-fdr"><span class="nav-link-text">Taggage</span>
                                </NuxtLink>
                              </li>
                              <li class="nav-item">
                                <NuxtLink class="nav-link" to="/categories-fdr"><span
                                    class="nav-link-text">Catégories</span></NuxtLink>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li> -->
                      <!-- <li class="nav-item">
                        <NuxtLink class="nav-link" to="/cartes-conseil"><span class="nav-link-text">Cartes conseils</span>
                        </NuxtLink>
                      </li>
                      <li class="nav-item">
                        <NuxtLink class="nav-link" to="/fiches-conseil"><span class="nav-link-text">Fiches conseils</span>
                        </NuxtLink>
                      </li>
                      <li class="nav-item">
                        <NuxtLink class="nav-link" to="" data-bs-toggle="collapse" data-bs-target="#dash_personnalises">
                          <span class="nav-link-text">Conseils Personnalisés</span>
                        </NuxtLink>
                        <ul id="dash_personnalises" class="nav flex-column collapse nav-children">
                          <li class="nav-item">
                            <ul class="nav flex-column">
                              <li class="nav-item">
                                <NuxtLink class="nav-link" to=""><span class="nav-link-text">Higiènes</span></NuxtLink>
                              </li>
                              <li class="nav-item">
                                <NuxtLink class="nav-link" to=""><span class="nav-link-text">Sécurité</span></NuxtLink>
                              </li>
                              <li class="nav-item">
                                <NuxtLink class="nav-link" to="/environnement-travail"><span
                                    class="nav-link-text">Environnement de travail</span></NuxtLink>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li> -->
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="nav-item" v-if="$ability.can('manage', sousMenuAdmin) ||
    $ability.can('manage', 'Gestion des réclamations et/avis') ||
    $ability.can('manage', 'all') ||
    $ability.can('manage', 'Consultation des réclamations') ||
    $ability.can('manage', 'Traitement des réclamations') ||
    $ability.can(
      'manage',
      'Consultation Dashboard (gestion des reclations et /ou avis)'
    )
    ">
                <NuxtLink class="nav-link" data-bs-toggle="collapse" data-bs-target="#dash_avis">
                  <span class="nav-icon-wrap">
                    <i class="bx bx-mail-send"></i>
                  </span>
                  <span class="nav-link-text">Réclamations</span>
                </NuxtLink>
                <ul id="dash_avis" class="nav flex-column collapse nav-children">
                  <li class="nav-item">
                    <ul class="nav flex-column">
                      <li class="nav-item">
                        <NuxtLink class="nav-link" to="/reclamations"><span class="nav-link-text">Adhérents</span>
                        </NuxtLink>
                      </li>
                      <li class="nav-item">
                        <NuxtLink class="nav-link" to="/reclamations/affilie"><span
                            class="nav-link-text">Affiliés</span>
                        </NuxtLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="nav-item" v-if="
                  $ability.can('manage', sousMenuAdmin) ||
                  $ability.can('manage', 'Gestion des réclamations et/avis') ||
                  $ability.can('manage', 'all') ||
                  $ability.can(
                    'manage',
                    'Consultation des avis et suggestions'
                  ) ||
                  $ability.can('manage', 'Traitement des avis et suggestions')
                  //||
                  //$ability.can(
                  //  'manage',
                  //  'Consultation Dashboard (gestion des reclations et /ou avis)'
                  //)
                ">
                <NuxtLink class="nav-link" data-bs-toggle="collapse" data-bs-target="#dash_avis">
                  <span class="nav-icon-wrap">
                    <i class="bx bx-star"></i>
                  </span>
                  <span class="nav-link-text">Avis</span>
                </NuxtLink>
                <ul id="dash_avis" class="nav flex-column collapse nav-children">
                  <li class="nav-item">
                    <ul class="nav flex-column">
                      <li class="nav-item">
                        <NuxtLink class="nav-link" to="/avis"><span class="nav-link-text">Adhérents</span>
                        </NuxtLink>
                      </li>
                      <li class="nav-item">
                        <NuxtLink class="nav-link" to="/Avis-affilie"><span class="nav-link-text">Affiliés</span>
                        </NuxtLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="nav-item" v-if="$ability.can('manage', sousMenuAdmin) ||
    $ability.can('manage', 'Gestion des réclamations et/avis') ||
    $ability.can('manage', 'all') ||
    $ability.can(
      'manage',
      'Consultation des avis et suggestions'
    ) ||
    $ability.can(
      'manage',
      'Traitement des avis et suggestions'
    ) ||
    $ability.can(
      'manage',
      'Consultation Dashboard (gestion des reclations et /ou avis)'
    )
    ">
                <NuxtLink class="nav-link" to="/suggestions-affilie">
                  <span class="nav-icon-wrap">
                    <i class="bx bx-message-rounded-add"></i>
                  </span>
                  <span class="nav-link-text">Suggestion</span>
                </NuxtLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Main Menu -->
    </div>
    <div id="hk_menu_backdrop" class="hk-menu-backdrop"></div>
  </div>
</template>
<style scoped>
u {
  cursor: pointer;
}

.nav-link-text {
  font-size: 14px;
}
</style>
